import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import { toast } from '@/layouts/components/ToastMessage';
import { buyCoinApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import ProjectSetting from '@/setting/projectSetting';
import RenderUtil from '@/utils/RenderUtil';
import { Button } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCoinModel } from '../coinModel';
import { TransactionModeEnum, TransactionTypeEnum } from '../type';
import { Rise } from '@/assets/icons/comm/Rise';
import { Drop } from '@/assets/icons/comm/Drop';
import { AddAmount } from '@/assets/icons/comm/AddAmount';
import AddMoneyEntranceButtonModal from '@/pages/transaction/components/AddMoneyEntranceButtonModal';

export default ({
                  onSuccess,
                  isBuyFull,
                  transactionMode: propsTransactionMode,
                  priceType: propsPriceType,
                }: IProps) => {
  const { t } = useTranslation();

  const modelProps = useCoinModel();
  const { amount, price } = modelProps || {};
  const transactionMode = propsTransactionMode || modelProps.transactionMode;
  const priceType = propsPriceType || modelProps.priceType;

  const { localCoin }: any = useModel('coin');
  const { getSocketRowByName } = useModel('socket');
  const { reLoadOrder, isPc } = useModel('system');
  const { coinsFunds } = useModel('user');
  const { appInfo } = useModel('appInfo');
  //币币最小购买
  const { COIN_MINIMUM_EXCHANGE_AMOUNT = 0.001 } = appInfo || {};

  const socketData = getSocketRowByName(localCoin?.symbol);
  //红涨绿跌设置
  const { currentDownBgClass, currentUpBgClass } = useModel('system');
  const { refreshWallet } = useModel('user');

  //最小购买
  const minBuyNum = useMemo(() => {
    return COIN_MINIMUM_EXCHANGE_AMOUNT / (socketData.a || 0.01);
  }, [COIN_MINIMUM_EXCHANGE_AMOUNT, socketData]);

  // 价格精度
  const priceAccuracyLength = localCoin?.priceAccuracy ?? 6;
  const transactionAccuracy = localCoin?.transactionAccuracy ?? 6;

  //购买现货
  const { run: buyCoinRequest } = useReq(buyCoinApi, {
    manual: true,
    loadingDefault: false,
    onSuccess: () => {
      if (transactionMode === 1) {
        toast.success(t('购买成功'));
      }
      if (transactionMode === 2) {
        toast.success(t('卖出成功'));
      }
      // refreshWallet();
      reLoadOrder();
    },
    onError: (e) => {
      console.log(e.message);
    },
  });
  const { renderFunds } = useModel('user');
  const renderList = useMemo(() => {
    return coinsFunds?.walletList?.map((item: any) => {
      // 查找socket推送的币币列表
      const findItem = renderFunds?.coin?.walletList?.find?.(
        (fundsItem: any) => {
          return fundsItem?.name === item?.name;
        },
      );
      return {
        ...item,
        availableBalance: findItem?.ab ?? item?.availableBalance,
        convertUsd: findItem?.abu ?? item?.convertUsd,
        freezeBalance: findItem?.fb ?? item?.freezeBalance,
      };
    });
  }, [coinsFunds, renderFunds]);
  // 获取可用金额
  const availableAmount = useMemo(() => {
    // 买入 交换币 -》基币钱包
    // 卖出 基币钱包 -》 交换币钱包
    if (localCoin && renderList?.length !== 0 && transactionMode === 1) {
      return renderList?.find(
        (item: any) => item.coinId === localCoin?.exchangeId,
      );
    }
    if (localCoin && renderList?.length !== 0 && transactionMode === 2) {
      return renderList?.find((item: any) => item.coinId === localCoin?.baseId);
    }
  }, [localCoin, coinsFunds, transactionMode]);

  // 手续费类型
  // 1 固定值：手续费
  // 2 百分比：买入->交易金额 * 手续费比例；卖出->交易金额 * 市价 * 手
  const commissionType = localCoin?.commissionType ?? 0;

  //手续费
  const commission = localCoin?.commission;

  // 限价的价格 或者行情价
  const memoPrice =
    +priceType === TransactionTypeEnum.LIMIT ? price : socketData?.a;

  // transactionMode 1 买入
  // transactionMode 2 卖入
  // 预估 手续费 计算
  const premium = useMemo(() => {
    // 固定手续费 -》 买入 和 卖出 都一样
    if (commissionType === 1) {
      return commission;
    }
    // 比例 买入计算 -》当前价 * 手续费比例
    if (commissionType === 2 && transactionMode === 1) {
      return amount * (commission * 0.01);
    }
    // 比例 卖出计算 -》交易金额 * 市价 * 1手
    if (commissionType === 2 && transactionMode === 2) {
      return amount * memoPrice * (commission * 0.01);
    }
  }, [
    commissionType,
    localCoin,
    commission,
    transactionMode,
    amount,
    memoPrice,
    price,
    priceType,
  ]);

  const { openPrompt } = useGlobalPrompt();

  return (
    <>
      <div className="mt-4">
        {/* 手续费，交易额 */}
        <div className="flex justify-between text-xs">
          <span className="text-auxiliaryTextColor">{t('可用')}</span>
          <div className="flex items-center gap-1">
             <span className="text-titleColor">
            <span>
              {RenderUtil.FormatAmount(
                availableAmount?.availableBalance,
                transactionMode === TransactionModeEnum.BUY ? ProjectSetting.usdAccuracyLength : transactionAccuracy,
                false,
              )}
            </span>
            <span>
              {transactionMode === 1 && ProjectSetting.APP_DEFAULT_CURRENCY}
              {transactionMode === 2 &&
                ' ' + (localCoin?.baseAlias ?? localCoin?.baseSymbol)}
            </span>
          </span>
            <AddMoneyEntranceButtonModal className="w-fit">
              <AddAmount />
            </AddMoneyEntranceButtonModal>
          </div>
        </div>
        <div className="flex justify-between text-xs my-1">
          <span
            className="text-auxiliaryTextColor border-b-1 border-dashed"
            onClick={() => {
              openPrompt({
                showCancel: false,
                title: t('预估手续费'),
                content:
                  commissionType === 1 ? (
                    <div>
                      {t('每笔订单收取')} {commission} {}
                    </div>
                  ) : (
                    <div>
                      {t('每笔订单收取交易金额的')} {commission}%
                    </div>
                  ),
              });
            }}
          >
            {t('预估手续费')}
          </span>
          <span className="text-titleColor">
            {RenderUtil.FormatAmount(premium, transactionMode === TransactionModeEnum.BUY ?
              transactionAccuracy
              : priceAccuracyLength, false)}
            {transactionMode === 1 &&
              ' ' + (localCoin?.baseAlias ?? localCoin?.baseSymbol)}
            {transactionMode === 2 && ProjectSetting.APP_DEFAULT_CURRENCY}
          </span>
        </div>
        {/*{!isPc && (*/}
        {/*  <div className="flex justify-between text-xs my-1">*/}
        {/*    <span className="text-auxiliaryTextColor">{t('交易额')}</span>*/}
        {/*    <span className="text-titleColor">*/}
        {/*      {RenderUtil.FormatAmount(*/}
        {/*        amount * memoPrice,*/}
        {/*        ProjectSetting.usdAccuracyLength,*/}
        {/*        false,*/}
        {/*      )}*/}
        {/*      {ProjectSetting.APP_DEFAULT_CURRENCY}*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className="flex justify-between text-xs my-1">
          <span className="text-auxiliaryTextColor">
            {transactionMode === TransactionModeEnum.BUY
              ? t('最小购买数')
              : t('最小卖出数')}
          </span>
          <span className="text-titleColor">
            {RenderUtil.FormatAmount(minBuyNum, 6, false)}
          </span>
        </div>

        <Button
          className={`flex flex-col bg-errorColor w-full my-2 text-[#fff] rounded-[6px] ${
            transactionMode === TransactionModeEnum.BUY
              ? currentUpBgClass
              : currentDownBgClass
          }`}
          onClick={() => {
            if (isNaN(amount) || amount <= 0) {
              return toast.error(t('请输入正确的数量'));
            }
            if (
              (+priceType === TransactionTypeEnum.LIMIT && isNaN(price)) ||
              price < 0
            ) {
              return toast.error(t('请输入正确的价格'));
            }

            buyCoinRequest({
              symbolCoinId: localCoin?.id,
              direction: transactionMode, //（1：买入，2：卖出）
              type: priceType, //（1：市价，2：限价）
              baseAmount: amount,
              limitPrice: price,
              isFull: isBuyFull,
            });
            onSuccess && onSuccess();
          }}
        >
          <div className="text-sm14 flex items-center">
            {transactionMode === TransactionModeEnum.BUY && (
              <Rise className="text-[#fff] mr-1" />
            )}
            {transactionMode === TransactionModeEnum.SELL && (
              <Drop className="text-[#fff] mr-1" />
            )}

            <div>
              {transactionMode === TransactionModeEnum.BUY
                ? t('买入')
                : t('卖出')}
              {` ${localCoin?.baseAlias}`}
            </div>
          </div>
        </Button>
      </div>
    </>
  );
};

interface IProps {
  isBuyFull?: boolean;
  onSuccess?: () => void;
  transactionMode?: number;
  priceType?: number;
}
