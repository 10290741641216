import { MdiRhombus } from '@/assets/font/MdiRhombus';
import ThemeEnum from '@/enums/themeEnum';
import { cn } from '@nextui-org/react';
import { useUpdateEffect } from 'ahooks';
import { useTheme } from 'next-themes';
import React, { useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring'; // 引入react-spring

interface BaseSlideRangeProps {
  values: any[];
  value: number;
  onChange: (value: number) => void;
  isShowButtonNumber?: boolean; //是否展示数字
  step?: number;
}

/**
 *  仿制币安 拉杆组件
 */

const BaseSlideRange: React.FC<BaseSlideRangeProps> = ({
  values,
  value,
  onChange,
  isShowButtonNumber=false,
  step = 1,
}) => {
  const slider = useRef<HTMLDivElement>(null);
  const [, setStartX] = useState(0);
  const [currentValue, setCurrentValue] = useState(value);
  const [isMoving, setIsMoving] = useState(false);
  const [startValue, setStartValue] = useState(value);

  useUpdateEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const getNodePosition = (index: number) => {
    if (index === 0) return 0;
    if (index === values.length - 1) return 100;
    return (index / (values.length - 1)) * 100;
  };

  const handleTouchMove = (event: TouchEvent | MouseEvent) => {
    // 阻止页面的默认滚动行为
    event.preventDefault();

    setStartX((prevStartX) => {
      setCurrentValue((prevState) => {
        const clientX =
          'touches' in event
            ? (event as TouchEvent).touches[0].clientX
            : (event as MouseEvent).clientX;
        const slide = slider.current?.offsetLeft ?? 0;
        const percentage = parseInt(
          ((clientX - slide) / slider.current!.offsetWidth) * 100 + '',
          10,
        );
        if (percentage >= 0 && percentage <= 100) {
          onChange(percentage);
          return percentage;
        }
        onChange(prevState);
        return prevState;
      });
      return prevStartX;
    });
  };

  // 结束滚动
  const handleTouchEnd = () => {
    setStartX(0);
    setIsMoving(false);

    // 恢复body滚动
    // document.body.style.overflowY = 'auto';
    document.removeEventListener('mouseup', handleTouchEnd);
    document.removeEventListener('mousemove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
    document.removeEventListener('touchmove', handleTouchMove);
  };

  // 开始滚动
  const handleTouchStart = (event: TouchEvent | MouseEvent) => {
    const clientX =
      'touches' in event
        ? (event as TouchEvent).touches[0].clientX
        : (event as MouseEvent).clientX;
    setStartValue(currentValue);
    setStartX(clientX);
    setIsMoving(true);

    // 禁用body滚动
    // document.body.style.overflowY = 'hidden';
    document.addEventListener('mouseup', handleTouchEnd);
    document.addEventListener('mousemove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('touchmove', handleTouchMove, { passive: false }); // 确保preventDefault生效
  };

  // 点击 滚动 节点
  const handleClickSlider = (event: TouchEvent | MouseEvent) => {
    if (slider.current) {
      setCurrentValue((prevState) => {
        const clientX =
          'touches' in event
            ? (event as TouchEvent).touches[0].clientX
            : (event as MouseEvent).clientX;
        const slide = slider.current?.offsetLeft ?? 0;
        const percentage = parseInt(
          ((clientX - slide) / slider.current!.offsetWidth) * 100 + '',
          10,
        );
        if (percentage >= 0 && percentage <= 100) {
          onChange(percentage);
          return percentage;
        }
        onChange(prevState);
        return prevState;
      });
    }
  };

  const { theme: nextUiTheme }: any = useTheme();

  // 使用react-spring的旋转动画，根据 currentValue 实时旋转
  const springProps = useSpring({
    transform: `rotate(${(currentValue - startValue) * 10}deg)`, // 每个百分比乘以角度
    config: { tension: 300, friction: 30 }, // 动画力度和阻力
  });

  return (
    <div className="overflow-visible min-h-4 px-2 h-fit">
      {/* 拉杆整体横线 */}
      <div
        ref={slider}
        onClick={handleClickSlider as any}
        onMouseDown={handleTouchStart as any}
        onTouchStart={handleTouchStart as any}
        className="relative h-4 select-none overflow-visible rounded-md bg-[transparent] w-full my-2 cursor-pointer"
      >
        <div
          className=" absolute top-[50%] bg-backgroundAuxiliaryColor w-full h-[2px] "
          style={{ transform: 'translateY(-50%)' }}
        />

        {/* 拉杆上面的节点 */}
        {values.map((val, index) => (
          <div
            key={val}
            onClick={(event) => {
              event.stopPropagation();
              onChange(val);
              setCurrentValue(val);
            }}
          >
            <div
              className={cn(
                `absolute group sm:cursor-pointer top-1/2 h-[7px] w-[7px] z-20 bg-background border-[1px] border-backContrastColor/10 rotate-45 transform -translate-y-1/2 rounded-[1.8px]`,
                {
                  '-translate-x-0': index === 0,
                  '-translate-x-2': index !== 0,
                  '!bg-backContrastColor': val < currentValue,
                },
              )}
              style={{ left: `${getNodePosition(index)}%` }}
            />
          </div>
        ))}

        {/* 菱形小图标 box */}
        <div
          className={cn(
            `absolute group top-1/2 !text-backContrastColor h-9 w-9 z-20 transform -translate-y-1/2 -translate-x-1/3 flex items-center bg-[transparent]`,
            {
              // 对最大和最小情况 处理下
              'ml-[3px]': currentValue < 5,
              'mr-[2px]': currentValue > 95,
            },
          )}
          style={{ left: `${currentValue}%` }}
        >
          {isMoving && (
            <div
              className={cn(
                `absolute top-0 -translate-y-full text-sm px-1 py-[1px] text-center rounded-md bg-backContrastColor text-background`,
                {
                  '-translate-x-1/2 left-1/2':
                    currentValue !== 0 && currentValue !== 100,
                  'left-0': currentValue === 0,
                  'right-3': currentValue === 100,
                },
              )}
            >
              {Math.ceil(currentValue)}%
            </div>
          )}
          {/* 使用react-spring控制的移动小图形 */}
          {/* 菱形小图标  */}
          <animated.div style={springProps}>
            <MdiRhombus
              className={cn(
                `sm:cursor-pointer !text-backContrastColor h-[17px] w-[17px]`,
              )}
              fill={nextUiTheme === ThemeEnum.dark ? '#000' : '#fff'}
            />
          </animated.div>
        </div>

        {/* 已经选中的 部分 */}
        <div
          className={cn(`absolute top-[50%] h-[2px] rounded-md bg-[#000]`, {
            'bg-[#fff]': nextUiTheme === ThemeEnum.dark,
            'bg-[#000]': nextUiTheme === ThemeEnum.light,
          })}
          style={{ width: `${currentValue}%`, transform: 'translateY(-50%)' }}
        />
      </div>
      {/* 下面是数字 */}
      {isShowButtonNumber&&<div className="relative mt-4 select-none">
        {values.map((val, index) => (
          <div key={val}>
            <div
              className={cn(
                `absolute text-titleColor text-center z-40 transform -translate-y-1/2  -translate-x-1/2 `,
                {
                  '-translate-x-0': index === 0,
                },
              )}
              style={{ left: `${getNodePosition(index)}%` }}
            >
              {val}%
            </div>
          </div>
        ))}
      </div>
      }
    </div>
  );
};

export default BaseSlideRange;
