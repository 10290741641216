import type { SVGProps } from 'react';

export function Drop(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.60188 8.76525V13.1223C3.60188 13.5037 3.91107 13.813 4.29264 13.813H4.66818C5.04967 13.813 5.35896 13.5037 5.35896 13.1223V9.53412L3.97417 8.15236C3.75299 8.26752 3.60188 8.49868 3.60188 8.76525ZM8.26588 6.8262L6.82232 8.26649V13.1223C6.82232 13.5037 7.1316 13.813 7.51308 13.813H7.88376C8.2652 13.813 8.57449 13.5037 8.57449 13.1223V7.40162C8.57451 7.16149 8.45188 6.95 8.26588 6.8262ZM0.38623 5.5573V13.1223C0.38623 13.5037 0.695418 13.813 1.07699 13.813H1.45256C1.83402 13.813 2.14331 13.5037 2.14331 13.1223V6.32557L0.759008 4.94412C0.537557 5.05919 0.38623 5.2905 0.38623 5.5573ZM13.5511 5.82272C13.5467 5.54229 13.2155 5.40198 12.9386 5.6782L12.0406 6.57436L8.9907 3.42854C8.9314 3.36731 8.85697 3.32991 8.77905 3.31578L8.71446 3.24928C8.56455 3.09513 8.31765 3.09127 8.163 3.24101L5.42864 5.88743L1.03337 1.54413C0.88105 1.39372 0.635802 1.39434 0.484127 1.54483V2.91792L5.19859 7.57666C5.35153 7.72778 5.59842 7.72642 5.74993 7.57396L6.16152 7.15911C6.21335 7.10697 6.24688 7.04374 6.26301 6.97716L8.45582 4.85468L11.066 7.54689L10.2002 8.41078C9.92331 8.68701 10.0599 9.02161 10.3412 9.02565L12.9912 9.08467C13.2722 9.08906 13.6182 8.74367 13.6139 8.46341L13.5511 5.82272ZM11.0016 10.2129H10.7287C10.3473 10.2129 10.038 10.5222 10.038 10.9036V13.1223C10.038 13.5037 10.3473 13.813 10.7287 13.813H11.0016C11.383 13.813 11.6924 13.5037 11.6924 13.1223V10.9036C11.6924 10.5222 11.3831 10.2129 11.0016 10.2129Z"
        fill="currentColor" />
    </svg>

  );
}
