import BaseModal from '@/components/base/baseModal';
import { useDisclosure } from '@nextui-org/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuickRecharge } from '@/assets/icons/comm/QuickRecharge';
import { C2CTransaction } from '@/assets/icons/comm/C2CTransaction';
import PageEnum from '@/enums/pageEnum';
import { history } from '@umijs/max';
import { BankCardRecharge } from '@/assets/icons/comm/BankCardRecharge';
import { VirtualCurrencyRecharge } from '@/assets/icons/comm/VirtualCurrencyRecharge';
import { Transfer } from '@/assets/icons/comm/Transfer';
import FeaturesQuickJumpModal from '@/components/features/featuresQuickJumpModal';

export default ({ children, className }: { children?: ReactNode, className?: string }) => {
  const { t } = useTranslation();

  // 跳转列表
  const [jumpList] = useState([
    {
      icon: QuickRecharge,
      title: t('快捷充值'),
      description: t('向您的账户充值数字货币'),
      path: PageEnum.TopUp,
    },
    {
      icon: C2CTransaction,
      title: t('C2C交易'),
      description: t('点对点交易，价格从优'),
      path: PageEnum.C2C_LIST,
    },
    {
      icon: Transfer,
      title: t('划转'),
      description: t('划转到现货账户'),
      path: PageEnum.TRANSFER,
    },
  ]);

  return <>
    <FeaturesQuickJumpModal jumpList={jumpList} className={className} title={t('增加余额')}>
      {children}
    </FeaturesQuickJumpModal>
  </>;
}