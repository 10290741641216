import type { SVGProps } from 'react';

export function Rise(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_747_3591)">
        <path
          d="M3.84312 8.63597V12.7417C3.84312 13.2129 4.22486 13.5949 4.6963 13.5949H4.76287C5.23406 13.5949 5.61605 13.213 5.61605 12.7417V9.25618L4.26287 7.90514C4.01254 8.05345 3.8432 8.32416 3.8432 8.63591L3.84312 8.63597ZM8.48022 8.37114L7.09801 9.75108V13.2008C7.09801 13.4185 7.27496 13.5954 7.49326 13.5954H8.48029C8.69841 13.5954 8.87553 13.4188 8.87553 13.2008V8.7651C8.87553 8.54774 8.69898 8.37122 8.48029 8.37122L8.48022 8.37114ZM0.584767 10.0462V12.7892C0.584767 13.2344 0.945705 13.5947 1.39027 13.5947H1.45848C1.90367 13.5947 2.26399 13.2338 2.26399 12.7892V9.27984C2.26399 9.0298 2.14803 8.80958 1.97013 8.66198L0.584052 10.0464L0.584767 10.0462ZM12.8817 0.447266L10.1929 0.507031C9.90781 0.511388 9.7691 0.850192 10.0499 1.13006L10.928 2.00531L6.70867 6.21954L4.46851 4.05353C4.45172 3.98901 4.4238 3.9275 4.37344 3.87658L3.95608 3.45644C3.80259 3.30176 3.55164 3.30045 3.39687 3.45385L0.48584 6.32655V8.2685L3.63017 5.16503L6.40469 7.84705C6.56154 7.99818 6.81164 7.99462 6.96363 7.8386L7.03757 7.76261C7.11334 7.74686 7.1862 7.71709 7.24383 7.65729L11.9169 2.99064L12.8287 3.89948C13.1097 4.17918 13.4459 4.03705 13.4502 3.75309L13.5139 1.0772C13.5175 0.793387 13.1666 0.44394 12.8815 0.447465L12.8817 0.447266ZM10.3517 6.50129V12.7421C10.3517 13.2133 10.7335 13.5953 11.2049 13.5953H11.2794C11.7506 13.5953 12.1326 13.2134 12.1326 12.7421V5.38507C12.1326 5.19809 12.0708 5.02674 11.9691 4.88601L10.3519 6.50151L10.3517 6.50129Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_747_3591">
          <rect width="14" height="14" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>

  );
}
