import type { SVGProps } from 'react';

export function MarketOrder(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_559_1118)">
        <line
          x1="2"
          y1="7"
          x2="30"
          y2="7"
          stroke="currentColor"
          stroke-width="2"
          stroke-dasharray="2 2"
        />
        <path
          d="M0 23L11 17.5L20.5 21L24 12"
          stroke="currentColor"
          stroke-width="2"
        />
          <circle cx="25" cy="10" r="3" stroke="currentColor" stroke-width="2"  />
      </g>
      <defs>
        <clipPath id="clip0_559_1118">
          <rect width="32" height="32"  fill="currentColor"/>
        </clipPath>
      </defs>
    </svg>
  );
}
