import type { SVGProps } from 'react';

export function AddAmount(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.00024 1.46094C3.65728 1.46094 0.947266 4.16473 0.947266 7.50004C0.947266 10.8353 3.65728 13.5391 7.00024 13.5391C10.3432 13.5391 13.0532 10.8353 13.0532 7.50004C13.0532 4.16473 10.3432 1.46094 7.00024 1.46094ZM10.0497 8.1372H7.63739V10.5495C7.63739 10.9014 7.35212 11.1867 7.00023 11.1867C6.64834 11.1867 6.36306 10.9014 6.36306 10.5495V8.1372H3.95077C3.59888 8.1372 3.31361 7.85192 3.31361 7.50004C3.31361 7.14815 3.59888 6.86287 3.95077 6.86287H6.36306V4.45058C6.36306 4.09869 6.64834 3.81342 7.00023 3.81342C7.35212 3.81342 7.63739 4.09869 7.63739 4.45058V6.86287H10.0497C10.4016 6.86287 10.6868 7.14815 10.6868 7.50004C10.6868 7.85192 10.4016 8.1372 10.0497 8.1372Z"
        fill="hsl(var(--nextui-primary)" />
    </svg>

  );
}
