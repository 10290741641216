export enum OrderEnum {
    //闪兑
    SWAP = 'swap',
    //现货
    SPOT = 'spot',
  }
  
  export enum TransactionModeEnum {
    //买入
    BUY = 1,
    //卖出
    SELL = 2,
  }
  
  // 定义交易类型的枚举
  export enum TransactionTypeEnum {
    // 限价交易
    LIMIT = 2,
    // 市价交易
    MARKET = 1,
    // 止盈止损
    STOP_LOSS = 3,
  }
  