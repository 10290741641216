import { MarketOrder } from '@/assets/icons/contractTrade/MarketOrder';
import { UnitPriceLimit } from '@/assets/icons/contractTrade/UnitPriceLimit';
import PageEnum from '@/enums/pageEnum';
import { useModel } from '@@/exports';
import { history } from '@umijs/max';
import { useDebounce, useThrottleEffect } from 'ahooks';
import { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderEnum, TransactionModeEnum, TransactionTypeEnum } from './type';

// 动态 ts 类型
export const CoinTradeContext = createContext<
  ReturnType<typeof createCoinModel> | undefined
>(undefined);

export const useCoinModel = () => {
  const context = useContext(CoinTradeContext);
  if (!context) {
    throw new Error('useCoinModel 现货报错');
  }
  return context;
};

export const createCoinModel = () => {
  const { t } = useTranslation();

  /*------------- 顶部tab----------*/
  const orderTabsOption = [
    {
      text: t('闪兑'),
      value: OrderEnum.SWAP,
    },
    {
      text: t('现货'),
      value: OrderEnum.SPOT,
    },
  ];

  const [orderTab, setOrderTab] = useState<OrderEnum>(OrderEnum.SPOT);
  const onOrderTabChange = (value: OrderEnum) => {
    if (value === OrderEnum.SWAP) {
      history.push(PageEnum.SWAP);
      return;
    }

    setOrderTab(value);
  };
  /*-----------限价市价-------------------*/
  const [priceType, setPriceType] = useState(TransactionTypeEnum.MARKET);
  // 现货的 止盈止损 类型的 触发价格
  const [activationPrice, setActivationPrice] = useState(
    TransactionTypeEnum.MARKET,
  );
  const onPriceTypeChange = (value: TransactionTypeEnum) => {
    setPriceType(value);
  };
  const priceTypeOption = [
    {
      text: t('限价单'),
      value: TransactionTypeEnum.LIMIT,
      remark: t('以指定或更优价格成交'),
      Icon: UnitPriceLimit,
    },
    {
      text: t('市价单'),
      value: TransactionTypeEnum.MARKET,
      remark: t('以当前价格快速成交'),
      Icon: MarketOrder,
    },
    // {
    //   text: t('止盈/止损'),
    //   value: TransactionTypeEnum.STOP_LOSS,
    // },
  ];

  const priceTypeText = useMemo(() => {
    return priceTypeOption.find(
      (item) => Number(item.value) === Number(priceType),
    )?.text;
  }, [priceType]);

  const [transactionMode, setTransactionMode] = useState<TransactionModeEnum>(
    TransactionModeEnum.BUY,
  );

  //价格计数器
  const [price, setPrice] = useState<number>(0);
  //数量
  const [amount, setAmount] = useState<number>(0.1);

  /*-----------买入卖出-------------------*/
  const changeTransactionMode = (value: TransactionModeEnum) => {
    setTransactionMode(value);
  };

  const onPriceChange = (value: any) => {
    setPrice(value);
  };
  const onAmountChange = (value: any) => {
    setAmount(value);
  };
  /*-----------波动提示-------------------*/
  const [isShowTip, setIsShowTip] = useState(true);

  const [orderKey, setOrderKey] = useState(1);
  const reloadOrder = () => {
    setOrderKey((old) => old + 1);
  };
  const { localCoin } = useModel('coin');

  const debouncedOrderKey = useDebounce(orderKey, { wait: 888 });

  useThrottleEffect(
    () => {
      reloadOrder();
    },
    [localCoin],
    { wait: 188 },
  );

  return {
    price,
    amount,
    onPriceChange,
    onAmountChange,
    priceType,
    onPriceTypeChange,
    priceTypeOption,
    priceTypeText,
    orderTabsOption,
    orderTab,
    onOrderTabChange,
    transactionMode,
    changeTransactionMode,
    isShowTip,
    setIsShowTip,
    setAmount,
    setPrice,
    orderKey: debouncedOrderKey,
    reloadOrder,
    activationPrice,
    setActivationPrice,
  };
};
