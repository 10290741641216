export function MdiRhombus(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill={props.fill} // 移除填充颜色
        stroke="currentColor" // 添加描边颜色
        strokeWidth="2" // 设置描边宽度
        d="M12 2c-.5 0-1 .19-1.41.59l-8 8c-.79.78-.79 2.04 0 2.82l8 8c.78.79 2.04.79 2.82 0l8-8c.79-.78.79-2.04 0-2.82l-8-8C13 2.19 12.5 2 12 2Z" // 确保路径闭合
      ></path>
    </svg>
  );
}
