import type { SVGProps } from 'react';

export function UnitPriceLimit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="2"
        y1="23"
        x2="30"
        y2="23"
        stroke="currentColor"
        stroke-width="2"
        stroke-dasharray="2 2"
      />
      <path
        d="M3 15.5L8 9L14 13.5L21.5 8L25 17"
        stroke="currentColor"
        stroke-width="2"
      />
      <circle cx="25" cy="20" r="3" stroke="currentColor" stroke-width="2"  />
    </svg>
  );
}
