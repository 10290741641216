import type { SVGProps } from 'react';

export function Transfer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props}>
      <path
        d="M0.369738 13.5185C0.308267 13.5233 0.249237 13.5447 0.198887 13.5803C0.148536 13.6158 0.108737 13.6644 0.083695 13.7207C0.058653 13.7771 0.0492988 13.8391 0.0566211 13.9003C0.0639434 13.9616 0.0876702 14.0197 0.125294 14.0685L6.54196 19.703C6.77418 19.8496 7.09196 19.7763 7.09196 19.4585V15.8896H21.172C21.2761 15.8949 21.3801 15.8783 21.4774 15.8408C21.5747 15.8034 21.6631 15.7459 21.7368 15.6722C21.8105 15.5985 21.8679 15.5102 21.9054 15.4129C21.9428 15.3156 21.9594 15.2115 21.9542 15.1074V14.3008C21.9594 14.1966 21.9428 14.0926 21.9054 13.9953C21.8679 13.898 21.8105 13.8097 21.7368 13.7359C21.6631 13.6622 21.5747 13.6048 21.4774 13.5674C21.3801 13.5299 21.2761 13.5133 21.172 13.5185H0.369738Z"
        fill="currentColor" />
      <path
        d="M21.8071 8.41978C21.7568 8.45537 21.6978 8.4767 21.6363 8.48151H0.785184C0.681072 8.48677 0.577014 8.47013 0.479729 8.43268C0.382443 8.39523 0.294092 8.3378 0.22038 8.26409C0.146668 8.19037 0.0892331 8.10202 0.0517834 8.00474C0.0143338 7.90745 -0.00229813 7.8034 0.00296142 7.69928V6.91706C-0.0069206 6.80652 0.00800157 6.69516 0.0466391 6.59112C0.0852766 6.48708 0.146655 6.39298 0.226295 6.31568C0.305935 6.23838 0.401827 6.17984 0.506975 6.14433C0.612123 6.10881 0.723874 6.09722 0.834073 6.1104H14.9141V2.54151C14.9141 2.22373 15.2319 2.15039 15.4641 2.29706L21.8807 7.93151C21.9184 7.98036 21.9421 8.03846 21.9494 8.09969C21.9567 8.16091 21.9474 8.22297 21.9223 8.27932C21.8973 8.33566 21.8575 8.38419 21.8071 8.41978Z"
        fill="hsl(var(--nextui-primary)" />
    </svg>

  );
}
