import BaseModal from '@/components/base/baseModal';
import { useDisclosure } from '@nextui-org/react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuickRecharge } from '@/assets/icons/comm/QuickRecharge';
import { C2CTransaction } from '@/assets/icons/comm/C2CTransaction';
import PageEnum from '@/enums/pageEnum';
import { history } from '@umijs/max';
import { BankCardRecharge } from '@/assets/icons/comm/BankCardRecharge';
import { VirtualCurrencyRecharge } from '@/assets/icons/comm/VirtualCurrencyRecharge';
import { Transfer } from '@/assets/icons/comm/Transfer';

export default ({ children, className, jumpList, title }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return <>
    <div onClick={onOpen} className={className}>
      {
        children ? children :
          <button className="bg-[transparent] border-borderColor border-1 px-4 text-[14px] !rounded-md h-[28px] !py-0">
            {title}
          </button>
      }
    </div>
    <BaseModal isOpen={isOpen} onClose={onClose} title={title}>
      <div className="px-4 pb-6 flex flex-col gap-3 pt-4">
        {
          jumpList?.map(item => {
            return <div className="border-1 rounded-md border-borderColor p-4 flex items-center gap-3" key={item?.title}
                        onClick={(e) => {
                          history.push(item.path);
                          onClose();
                          e.stopPropagation();
                        }}>
              <item.icon />
              <div className="flex flex-col gap-1">
                <div className="text-[16px] text-backContrastColor">
                  {item?.title}
                </div>
                <div className="text-[12px] text-auxiliaryTextColor">
                  {item?.description}
                </div>
              </div>
            </div>;
          })
        }
      </div>
    </BaseModal>
  </>;
}

interface IProps {
  children?: ReactNode,
  className?: string,
  title?: string,
  jumpList?: Array<{
    icon: (data: any) => JSX.Element,
    title: string,
    description: string,
    path: string
  }>
}