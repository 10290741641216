import { IconParkOutlineRight } from '@/assets/icons/comm/IconParkOutlineRight';
import BaseConfirmModal from '@/components/base/baseConfirmModal';
import { WalletTypeEnum } from '@/enums/businessEnum';
import OrderCardItem from '@/pages/order/index/components/OrderCardItem';
import { closeCoinOrderApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@@/core/history';
import { Button, cn, Divider, useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';

interface IProps {
  index: number;
  item: any;
  refreshOrder: any;
}

export default ({ index, item, refreshOrder }: IProps) => {
  const { t } = useTranslation();
  // 基础定义
  const [curId, setCurId] = useState(0);
  const { walletFundReq } = useModel('user');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const priceAccuracy = item?.priceAccuracy ?? 6;
  const transactionAccuracy = item?.transactionAccuracy ?? 6;
  // 红涨绿跌设置
  const {
    currentUpClass,
    currentUpBgClass,
    currentDownClass,
    currentDownBgClass,
  } = useModel('system');
  //撤销订单
  const { run: closeCoinOrderRequest } = useReq(closeCoinOrderApi, {
    manual: true,
    onSuccess: () => {
      refreshOrder();
      walletFundReq({
        walletType: WalletTypeEnum.CONTRACT,
      });
      toast.success(t('撤销订单成功'));
    },
  });

  //渲染Item
  const renderItem = [
    {
      label: t('成交数量'),
      value: RenderUtil.FormatAmount(item.baseAmount, transactionAccuracy),
    },
    // 委托价格
    {
      label: t('委托价格'),
      value: RenderUtil.FormatAmount(item?.limitPrice, priceAccuracy),
      condition: item?.status === 1,
    },
    // 委托中是没有成交价格的
    {
      label: t('成交价格'),
      value: RenderUtil.FormatAmount(
        item?.endPrice ?? item?.startPrice,
        priceAccuracy,
      ),
      condition: item?.status !== 1,
    },
    // {
    //     label: t('类型'),
    //     value: (
    //         <span>
    //     {item?.type === 1 && t('市价')}
    //             {item?.type === 2 && t('限价')}
    //   </span>
    //     ),
    // },
    // {
    //     label: t('状态'),
    //     value: (
    // <>
    //     {item?.status === 1 && (
    //         <span className=" text-warningColor">{t('委托中')}</span>
    //     )}
    //     {item?.status === -1 && (
    //         <span className={currentDownClass}>{t('已撤销')}</span>
    //     )}
    //     {item?.status === 0 && (
    //         <span className={currentUpClass}>{t('已结算')}</span>
    //     )}
    // </>
    //     ),
    // },
  ];
  return (
    <>
      <OrderCardItem
        item={item}
        classNames={{
          base: '!mb-0 !mt-1',
          divider: '!hidden',
          title: item?.status !== 1?'!items-start':'',
        }}
        itemIndex={index}
        infoArray={renderItem}
        cardWrapper={'flexBetween'}
        title={
          <div>
            <div className="flex flex-col">
              <span className="text-titleColor font-bold flex items-center">
                {item?.alias}
                {item?.status === 1 && (
                  <>
                    <IconParkOutlineRight className="text-auxiliaryTextColor flex items-center text-xs ml-[4px]" />
                  </>
                )}
              </span>
              <div className="flex items-center mt-[2px]  gap-2">
                {/* 购买 */}
                {item?.direction === 1 && (
                  <span
                    className={cn(
                      currentUpClass,
                      `bg-successColor !text-[#fff] h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                    )}
                  >
                    {t('购买')}
                  </span>
                )}
                {item?.direction === 2 && (
                  <span
                    className={cn(
                      currentDownClass,
                      `bg-errorColor !text-[#fff] h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                    )}
                  >
                    {t('卖出')}
                  </span>
                )}
                {/* 类型 */}
                {item?.type === 1 && (
                  <span className="h-fit font-normal bg-backgroundAuxiliaryColor rounded-[4px] p-1 py-[2px] text-xs">
                    {t('市价')}
                  </span>
                )}
                {item?.type === 2 && (
                  <span className="h-fit font-normal bg-backgroundAuxiliaryColor rounded-[4px] p-1 py-[2px] text-xs">
                    {t('限价')}
                  </span>
                )}
                {/* 状态 */}
                {/* {item?.status === 1 && (
                  <span className="text-xs text-warningColor">
                    {t('委托中')}
                  </span>
                )}
                {item?.status === -1 && (
                  <span className={cn(currentDownClass, 'text-xs')}>
                    {t('已撤销')}
                  </span>
                )}
                {item?.status === 0 && (
                  <span className={cn(currentUpClass, 'text-xs')}>
                    {t('已结算')}
                  </span>
                )} */}
                {/* 时间 */}
                <span className="text-auxiliaryTextColor flex items-center text-xs">
                  {RenderUtil.formatDate(item?.createTime,"YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>
            </div>
          </div>
        }
        icon={
          item?.status === 1 ? (
            <>
              <div className="flex">
                <Button
                  color="primary"
                  size="sm"
                  variant="light"
                  className="p-0 m-0 !h-fit !w-fit !min-w-0 !min-h-0 !rounded-none"
                  onClick={() => {
                    setCurId(item.id);
                    onOpen();
                    return false;
                  }}
                >
                  <span className="text-primary text-xs">{t('撤销')}</span>
                </Button>
              </div>
            </>
          ) : null
        }
        onClickCard={() => {
          history.push(`/order/detail/${item.id}?type=coin`);
        }}
      />

      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
      <BaseConfirmModal
        title={t('请确认')}
        isOpen={isOpen}
        onClose={onClose}
        onOk={() => {
          onClose();
          closeCoinOrderRequest(curId);
        }}
      >
        <p>{t('请确认是否撤销订单！')}</p>
      </BaseConfirmModal>
    </>
  );
};
